import React, { useEffect, useState } from "react"
import { Box } from "grommet"
import { graphql } from "gatsby"
import styled from "styled-components"
import { navigate } from "gatsby"
import Layout from "../../../containers/layout"
import Image from "../../../components/Image"
import { format, parseISO } from 'date-fns'
import CardList from "../../../components/CardListing/NewCardList"
import querystring from "query-string"
import Ad from "../../../containers/Ad"

const ImageContainer = styled.div`
  cursor: pointer;
`

const Component = ({ data, location }) => {
  const qs = querystring.parse(location.search)
  const [channel, setChannel] = useState("")
  const renderImage = (item, index) => {
    return (
      <ImageContainer
        onClick={() => {
          navigate(`/videos/view?id=${item.id}`)
        }}
      ><Box> 
        <Image
          src={item.image}
          imageProps={{
            fit: "cover",
            fill: "true",
          }}
        />
        <Box direction="row" gap="small" justify="center">
        </Box>

        {item.title} <br />
        
        </Box>
      </ImageContainer>
    )
  }
  const d = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) 


  const [datasource, setDatasource] = useState([])
  const [channels, setChannels] = useState([])
  const [recentVideoDS, setRecentVideoDS] = useState([])
  useEffect(() => {
    let result = []

    const allVideos = data.allAggVideo.edges;

    const recentVideos = allVideos
    .filter(({ node }) => node.channelId === qs.id)
    .map(({ node }) => {
      console.log(node)
      return {
        id: node.key,
        image: node?.thumbnails?.high?.url,
        title: node.title,
        publishTime: node.publishTime,
        channelId: node.channelId
      }
    })

    const channel = data.allChannel.edges
    .find(({ node }) => node.channelId === qs.id)
    
    setChannel(channel)


    setRecentVideoDS(recentVideos)
  }, [data.allAggVideo.edges, data.allChannel.edges])
  return (
    <>
      {
        <Layout max="100%">
          <Ad />
          <Box gap="small">
            <h3> { channel?.node?.title } </h3>
            <CardList dataList={recentVideoDS} render={renderImage} />

            
          </Box>

          
        </Layout>
      }
    </>
  )
}

export default Component

export const query = graphql`
query MyQuery33ee3 {
  allAggVideo(sort: {fields: publishedAt, order: DESC}) {
    edges {
      node {
        key
        channelId
        title
        thumbnails {
          high {
            url
          }
        }
        publishedAt
        publishTime
      }
    }
  }

  allChannel {
    edges {
      node {
        id
        title
        channelId
      }
    }
  }
}
`
